import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import tss from "../images/tss.png";
import '../styles/main.css'
import { StaticQuery, graphql } from 'gatsby'
import styles from "./partnerContent.module.css"


export default ()=> (
     <StaticQuery
        query={graphql`
      query partnersheadingQuery {
             
        partnersHeadingYaml {
           Heading
        }

        partnersContentYaml{
            Description
        }

      }
    `}
        render={data => (

    <div>

      
      <div className="container ">
        <div className="row">
            < div className = "col-lg-12 flex" >
                <div className="partner-img">
                    <img src={tss} className={styles.tssImg} alt="tss logo" id="tss" />
                  
                </div>
            </div>

          <div className={`col-lg-12 flex ${styles.flex}`} >
                <div className="col-lg-6 col-lg-offset-3">
                   
                    <div className="partner-content">
                        <h2 className={styles.partnersHeadingYaml} >{data.partnersHeadingYaml.Heading}</h2>
                        <br/>
                        <p className="content">
                            {data.partnersContentYaml.Description}
                        </p>
                        <br/>
                        <a href="https://www.targetstatesolutions.com/" className="btn btn-default btn-lg" id="learn_more"
                            target="_blank" rel="noopener noreferrer" alt="tss">Learn More</a>
                    </div>
                </div>
            </div>
        </div>

        </div>
          

    </div>

       )
       }
       />
       )



