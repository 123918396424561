import React from "react";
// import '../styles/main.css';

import logo from '../../src/images/Logo.png'

import Header from '../components/Header';
import SheetkraftFooter from '../components/SheetkraftFooter.js';
import PartnerContent from '../components/PartnerContent';
import Fade from 'react-reveal/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import styles from './partners.module.css';

export default class Partners extends React.Component {
  constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
     }

  //for scroll
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    let sk_sticky = document.querySelector('.sk-adaptive-header');
     sk_sticky.classList.add('sk-is-sticky');
    
    if (document.documentElement.scrollTop === 0) {
     sk_sticky.classList.remove('sk-is-sticky');
    }
  }
  render() {
    // let samePage = "yes";
    // console.log(this.props);
    
    return (
      <div>
        < Helmet >
          
          <meta name="description" property="og:description" content="SheetKraft builds high quality web-based applications using pre-designed powerful building blocks and connects to diverse sources of data across all your internal business applications"/>
          <meta name="title" property="og:title" content="SheetKraft"/>
          <meta name="image" property="og:image" content={logo}/>
          <meta name="author" content="SheetKraft" />
          < meta name = "keywords"
          content = " automation, automation anywhere, uipath, rpa, robotic process automation, automate processes, robotic automation process, rpa services, rpa software, rpa tools, blue prismno code, rapid application development,business process automation" />
          
          <title>SheetKraft - Partners</title>

        </Helmet>
        <Header />        
      
        <Fade bottom>
            <div id="partners" className={styles.partners}>
              < PartnerContent/>
            </div>
        </Fade>
            
        < SheetkraftFooter />
        
      </div>
    )
  }
  
}